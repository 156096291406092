import React, { useState } from 'react';

import { DynamicContentComponent } from '@lerna-monorepo/common/components/dynamicContentComponent/dynamicContentComponent.component';
import { ButtonIconAdvertisingSection } from '@lerna-monorepo/common/components/buttonIconAdvertisingSection/buttonIconAdvertisingSection.component';
import { Color } from '@lerna-monorepo/common/theme';
import { EmailTemplate } from '@lerna-monorepo/common/enums/emailTemplate.enum';
import { DynamicContent } from '@lerna-monorepo/common/interfaces/dynamicContent.types';
import { NameValueString } from '@lerna-monorepo/common/interfaces/nameValueString.types';
import { PhotoHeader } from '@lerna-monorepo/common/components/photoHeader/photoHeader.component';
import { ContactFormSection } from '@lerna-monorepo/common/components/contactFormSection/contactFormSection.component';
import { useScrollToSection } from '@lerna-monorepo/common/utils/useScrollToSection';
import { CurrentLanguageContext } from '@lerna-monorepo/common/contexts';
import { ApiService } from '../../services/api.service';

import {
  DynamicContentComponentContainer,
  ContactFormSectionWrapper,
  HalfBox,
  DynamicContentGroupContainerTop,
  DynamicContentGroupContainerBottom,
  LocalProductsSection,
  LocalCaseStudySection,
  RelatedCaseStudyRelatedProductsContainer,
} from './eventPromo.styled';
import { EventPromoPageData } from './eventPromo.types';
import { renderWhenTrue } from '@lerna-monorepo/common/utils';

const EventPromoPage: React.FC<EventPromoPageData> = (pageData: EventPromoPageData) => {
  const { data, currentLanguage, caseStudyBasePath, basePaths, productsBasePath } = pageData.pageContext;
  const {
    headerContent,
    contentBeforeForm,
    contactFormSection,
    contactFormSectionSecond,
    contentAfterForm,
    contentBetweenForm,
    buttonIconAdvertisingSection,
    relatedProducts,
    relatedCaseStudies,
    relatedCaseStudiesHeader,
    relatedProductsHeader,
    relatedProductsDescription,
  } = data;
  const { sectionRef, handleScroll } = useScrollToSection();

  const [formStatusLabel, setFormStatusLabel] = useState('');
  const [formStatus, setFormStatus] = useState(null);

  const contactFormSubmitHandler = (data: NameValueString[], receiverName: string) => {
    const api = new ApiService();
    const dataWithTitle = {
      ...data,
      receiverName,
      template: EmailTemplate.Custom,
    };

    api
      .sendContactFormMessage(dataWithTitle)
      .then(() => {
        setFormStatus(true);
        setFormStatusLabel(contactFormSection.successMessage);
        setTimeout(() => setFormStatus(null), 2000);
      })
      .catch(() => {
        setFormStatus(false);
        setFormStatusLabel(contactFormSection.failMessage);
        setTimeout(() => setFormStatus(null), 2000);
      });
  };

  const renderContent = (content: DynamicContent[]) =>
    content.map((caseStudyContentItem: DynamicContent) => (
      <DynamicContentComponentContainer
        key={`${caseStudyContentItem.header.bigHeader.mainContent}${caseStudyContentItem.header.bigHeader.descriptionContent}`}
      >
        <DynamicContentComponent
          header={caseStudyContentItem.header}
          dynamicContent={caseStudyContentItem.dynamicContent}
        />
      </DynamicContentComponentContainer>
    ));

  const renderCaseStudySection = renderWhenTrue(() => (
    <LocalCaseStudySection
      header={relatedCaseStudiesHeader}
      caseStudyBoxes={relatedCaseStudies}
      labelBackgroundColor={Color.veryLightGrey2}
      boxesBaseUrl={caseStudyBasePath}
      currentLanguage={currentLanguage}
      basePaths={basePaths}
    />
  ));

  const renderProductSection = renderWhenTrue(() => (
    <LocalProductsSection
      header={relatedProductsHeader}
      description={relatedProductsDescription}
      products={relatedProducts}
      boxesBaseUrl={productsBasePath}
      currentLanguage={currentLanguage}
    />
  ));

  return (
    <CurrentLanguageContext.Provider value={currentLanguage}>
      <PhotoHeader
        subheaderContent={headerContent.subheaderContent}
        headerContent={headerContent.headerContent}
        buttonLabel={headerContent.buttonLabel}
        background={headerContent.background}
        videoBackground={headerContent.videoBackground}
        handleScroll={handleScroll}
      />
      <DynamicContentGroupContainerTop>
        {renderContent(contentBeforeForm)}
      </DynamicContentGroupContainerTop>
      <ContactFormSectionWrapper ref={sectionRef}>
        <ContactFormSection
          sectionHeader={contactFormSection.sectionHeader}
          contactFormRows={contactFormSection.contactFormRows}
          agreements={contactFormSection.agreements}
          sendButtonLabel={contactFormSection.sendButtonLabel}
          onSubmit={(data: NameValueString[]): void => contactFormSubmitHandler(
            data, contactFormSection.receiverName
          )}
          status={formStatus}
          statusLabel={formStatusLabel}
          currentLanguage={pageData.pageContext.currentLanguage}
        />
      </ContactFormSectionWrapper>
      <DynamicContentGroupContainerBottom>
        {renderContent(contentBetweenForm)}
      </DynamicContentGroupContainerBottom>
      <ContactFormSectionWrapper ref={sectionRef}>
        <ContactFormSection
          sectionHeader={contactFormSectionSecond.sectionHeader}
          contactFormRows={contactFormSectionSecond.contactFormRows}
          agreements={contactFormSectionSecond.agreements}
          sendButtonLabel={contactFormSectionSecond.sendButtonLabel}
          onSubmit={(data: NameValueString[]): void => contactFormSubmitHandler(
            data, contactFormSectionSecond.receiverName
          )}
          status={formStatus}
          statusLabel={formStatusLabel}
          currentLanguage={pageData.pageContext.currentLanguage}
        />
      </ContactFormSectionWrapper>
      <DynamicContentGroupContainerBottom>
        {renderContent(contentAfterForm)}
        <HalfBox />
      </DynamicContentGroupContainerBottom>
      <RelatedCaseStudyRelatedProductsContainer>
        {renderCaseStudySection(!!relatedCaseStudies)}
        {renderProductSection(!!relatedProducts)}
      </RelatedCaseStudyRelatedProductsContainer>
      <ButtonIconAdvertisingSection
        icon={buttonIconAdvertisingSection.icon}
        textArray={buttonIconAdvertisingSection.textArray}
        buttonLabel={buttonIconAdvertisingSection.buttonLabel}
        buttonLink={buttonIconAdvertisingSection.buttonLink}
        backgroundColor={Color.white}
      />
    </CurrentLanguageContext.Provider>
  );
};

export default EventPromoPage;
